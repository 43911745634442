import {uniq} from 'lodash';

import {RecipeCollectionModel} from '../customGraphQlModels/ProductRecipeCollectionModel';

export const getWeeksFromRecipeCollectionModel = (
  recipeCollections: Array<RecipeCollectionModel>
) =>
  uniq(
    recipeCollections
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map(i => i.day)
  );
